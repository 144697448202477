import React, {useEffect, useState} from "react";
import Lottie from "react-lottie";
import {useDrop} from "react-dnd";
import target from "../../assets/svg/target.json";
import {Item} from "../Item/Item";
import animation from "../../assets/svg/chemistry-animation.json";

type FirstStepProps = {
  get_problem_result: (phase: number, reactants: any[], reagent: any[]) => Promise<{ phase: number; data: any[]; }>
  set_result: (result: any[]) => void
  current_result: any[]
  mobile: boolean
  start_animation?: boolean
  clear_data: boolean
};

export const FirstStep: React.FC<FirstStepProps> = (props) => {

  const {get_problem_result, set_result, current_result, mobile, start_animation} = props

  const [SelectedReactants, setSelectedReactants] = useState<any[]>([])
  const [SelectedReagents, setSelectedReagents] = useState<any[]>([])

  const [Result, setResult] = useState<any>([])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
  };

  const targetOptions = {
    loop: true,
    autoplay: true,
    animationData: target,
  };

  const deleteReactant = (id: number) => {
    const reactantsArray = [...SelectedReactants]
    const filtered = reactantsArray.filter(function (item) {
      return item.id !== id
    })
    setSelectedReactants(filtered)
  }

  const deleteReagent = (id: number) => {
    const reagentsArray = [...SelectedReagents]
    const filtered = reagentsArray.filter(function (item) {
      return item.id !== id
    })
    setSelectedReagents(filtered)
  }

  const [{isOverReactant, canDropReactant}, dropRefReactant] = useDrop({
    accept: 'reactant',
    drop: (item) => setSelectedReactants((SelectedReactants: unknown[]) => {
      return !SelectedReactants.includes(item) ? [...SelectedReactants, item] : SelectedReactants;
    }),
    collect: (monitor) => ({
      isOverReactant: monitor.isOver(),
      canDropReactant: monitor.canDrop()
    })
  })

  const [{isOverReagents, canDropReagents}, dropRefReagents] = useDrop({
    accept: 'reagent',
    drop: (item) => setSelectedReagents((SelectedReagents: unknown[]) => {
      if (SelectedReagents.length === 0) {
        return [...SelectedReagents, item]
      } else {
        return SelectedReagents
      }
    }),
    collect: (monitor) => ({
      isOverReagents: monitor.isOver(),
      canDropReagents: monitor.canDrop()
    })
  })

  const formatDataAndSend = async () => {
    const result = await get_problem_result(1, SelectedReactants, SelectedReagents)
    const results = [...current_result]
    const current_result_filtered = results.find(res => res.phase === result.phase)
    if (current_result_filtered === undefined) {
      set_result([...results, result])
    } else {
      const newResult = results.filter(ite => ite.phase !== result.phase)
      newResult.push(result)
      set_result(newResult)
    }
  }

  useEffect(() => {
    const result = current_result.find( item => item.phase === 1)
    if (result) {
      setResult(result.data)
    } else {
      setResult([])
    }

  }, [current_result])

  useEffect(() => {
    if (props.clear_data === true) {
      setSelectedReactants([])
      setSelectedReagents([])
    }
  }, [props.clear_data])

  return (
    <>
      {!mobile && <div className={"col-4 d-flex"}>
        <div
          className={"card-blue-border me-3 text-center d-flex justify-content-center align-items-center flex-column"}
          ref={dropRefReactant}>
          {!canDropReactant && SelectedReactants.map((reactant: { id: any; name: any; image: string; smiles: string }) => {
              return (
                <div key={'reactant-idp-' + reactant.id} onClick={() => deleteReactant(reactant.id)}>
                  <Item extraClass={''} id={reactant.id} image={reactant.image} smiles={reactant.smiles}/>
                </div>
              )
            }
          )
          }
          {!isOverReactant && !canDropReactant && SelectedReactants.length === 0 &&
            <span>Drag and drop one or more reactants</span>}
          {isOverReactant && <div>Drop Here!</div>}
          {canDropReactant && !isOverReactant && <Lottie options={targetOptions} height={100} width={100}/>}
        </div>
        <div className={"d-flex flex-column"}>
          <div
            className={"card-red-border text-center d-flex flex-column justify-content-center align-items-center mt-auto"}
            ref={dropRefReagents}>
            {SelectedReagents.map((reagent: { id: any; name: any; image: string; text: string }, index: number) => {
                return (
                  <div key={index} onClick={() => deleteReagent(reagent.id)}>
                    <Item
                      extraClass={''}
                      id={reagent.id}
                      image={reagent.image}
                      text={reagent.text}
                    />
                  </div>
                )
              }
            )
            }
            {!isOverReagents && !canDropReagents && SelectedReagents.length === 0 &&
              <span >Drag and drop a Reagent</span>}
            {isOverReagents && SelectedReagents.length === 0 && <span>Drop Here!</span>}
            {!isOverReagents && canDropReagents && SelectedReagents.length === 0 && <div>
              <Lottie
                options={targetOptions}
                height={100}
                width={100}
              />
            </div>}
          </div>
          <svg className={"mx-auto mt-3"} width="75" height="6" viewBox="0 0 75 6" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M75 3L70 0.113249V5.88675L75 3ZM0 3.5H70.5V2.5H0V3.5Z" fill="#6B6A6A"/>
          </svg>
          <div
            className={"start-reaction-button d-flex justify-content-center align-items-center mt-2"}
            onClick={() => formatDataAndSend()}>
            Start reaction
          </div>
        </div>
        <div
            className={" card-blue-border p-3 text-center d-flex flex-column justify-content-center align-items-center ms-3"}>
          {Result.length > 0 && <>
            {Result.map((res: any, index: number) => {
              if (res.url) {
                return (
                    <div key={index}>
                      <Item extraClass={''} id={index} image={res.url} smiles={res.smiles}/>
                    </div>
                )
              } else {
                return (
                    <span  key={index} style={{fontSize: 12}} className={'mx-4'}>{res.message}</span>
                )
              }
            })}
          </>}
          {Result.length === 0 && <>
            {start_animation && <div className={'mt-auto'}><Lottie
                options={defaultOptions}
                height={48}
                width={48}
            /></div>
            }
            {!start_animation && <>
              <span className={"mt-auto"}>(Intermediate)</span>
              <span className={"mb-auto"}>Products</span>
            </>}
          </> }

        </div>
      </div>}

      {mobile &&
        <div className={"row"}>
          <div
            className={"col-4 card-blue-border-selected p-3 text-center d-flex flex-column justify-content-center align-items-center"}
            ref={dropRefReactant}>
            {SelectedReactants.map((reactant: { id: any; name: any; image: string; smiles: string }, index: number) => {
                return (
                  <div className={''} key={index}
                       onClick={() => deleteReactant(reactant.id)}>
                    <Item
                      extraClass={''}
                      id={reactant.id}
                      image={reactant.image}
                      smiles={reactant.smiles}
                      mobile={true}
                    />
                  </div>
                )
              }
            )
            }
            {!isOverReactant && SelectedReactants.length === 0 && <div>Select reactant(S)</div>}
            {isOverReactant && <div>Drop Here!</div>}
          </div>
          <div className={"col-4 d-flex flex-column"}>
            <div
              className={"card-red-border-selected  text-center d-flex justify-content-center align-items-center"}
              ref={dropRefReagents}>
              {SelectedReagents.map((reagent: { id: any; name: any; image: string; text: string }, index: number) => {
                  return (
                    <div key={index} onClick={() => deleteReagent(reagent.id)}>
                      <Item
                        extraClass={''}
                        id={reagent.id}
                        image={reagent.image}
                        text={reagent.text}
                        mobile={true}
                      />
                    </div>
                  )
                }
              )
              }
              {!isOverReagents && SelectedReagents.length === 0 && <div>Select a Reagent</div>}
              {isOverReagents && <div>Drop Here!</div>}
            </div>
            <svg className={"mx-auto mt-3"} width="75" height="6" viewBox="0 0 75 6" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M75 3L70 0.113249V5.88675L75 3ZM0 3.5H70.5V2.5H0V3.5Z" fill="#6B6A6A"/>
            </svg>
          </div>
          <div
            className={"col-4 card-blue-border-first p-3 text-center d-flex flex-column justify-content-center align-items-center"}>
            {Result.length > 0 && <>
              {Result.map((res: any, index: number) => {
                if (res.url) {
                  return (
                    <div key={index}>
                      <Item extraClass={''} id={index} image={res.url} smiles={res.smiles}/>
                    </div>
                  )
                } else {
                  return (
                    <span  key={index} style={{fontSize: 12}} className={'mx-4'}>{res.message}</span>
                  )
                }
              })}
            </>}
            {Result.length === 0 && <>
              {start_animation && <div className={'mt-auto'}><Lottie
                options={defaultOptions}
                height={48}
                width={48}
              /></div>
              }
              {!start_animation && <>
                <span className={"mt-auto"}>(Intermediate)</span>
                <span className={"mb-auto"}>Products</span>
                <span className={"question-mark"}>?</span>
              </>}
            </> }

            <div
              className={"start-reaction-button d-flex justify-content-center align-items-center mt-auto"}
              onClick={() => formatDataAndSend()}>
              Start reaction
            </div>
          </div>
        </div>
      }
    </>

  )
}
