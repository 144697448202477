import React, {useEffect, useState} from "react";
import Lottie from "react-lottie";
import animation from "../../assets/svg/chemistry-animation.json";
import {OverlayTrigger, Popover} from "react-bootstrap";
import InfoButton from "../InfoButton/InfoButton";

type LastStepProps = {
  target_product: string
  start_animation: boolean
  current_phase: number
  current_result: any[]
  mobile: boolean
};

export const LastStep: React.FC<LastStepProps> = (props) => {

  const {current_result, start_animation, target_product, current_phase, mobile} = props

  const [Result, setResult] = useState<any>([])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
  };

  useEffect(() => {
    const prevPhase = current_phase
    const result = current_result.find( item => item.phase === prevPhase)
    if (result) {
      setResult(result.data)
    } else {
      setResult([])
    }

  }, [current_result])

  return (
    <>
      {!mobile && (
        <div className={"col-4 d-flex"}>
          <div
            className={"card-dotted-border text-center d-flex flex-column justify-content-center align-items-center col-6"}>
            {start_animation && Result.length === 0 && <div className={'p-5'}><Lottie
              options={defaultOptions}
              height={48}
              width={48}
            />
            </div>}
            {Result.map((res: any) => {
              if (res.url) {
                return (
                  <img src={res.url} alt={""} style={{width: 100, height: 100}}/>
                )
              } else {
                return (
                  <span style={{fontSize: 12}} className={'mx-4'}>{res.message}</span>
                )
              }
            })}
          </div>
          {/*Target product*/}
          <div
            className={"target-product-container ms-3 d-flex flex-column align-items-center justify-content-center position-relative"}>
            <div className={"title-result-header d-flex justify-content-center align-items-center"}>
              Target product <InfoButton title={'Target product'} content={'Generate the product shown above by combining reactants and reagents in the proper sequence.'}/>
            </div>
            <div className={"d-flex justify-content-center align-items-center"}>
              <img
                className={"target-product"}
                src={target_product}
                alt={""}/>
            </div>
          </div>
        </div>
      )}
      {mobile && (
        <div className={"col-4 d-flex"}>
          <div
            className={"card-dotted-border-intermediate text-center d-flex flex-column justify-content-center align-items-center col-6"}>
            {start_animation && Result.length === 0 && <div className={'p-5'}><Lottie
              options={defaultOptions}
              height={48}
              width={48}
            />
            </div>}
            {Result.map((res: any) => {
              if (res.url) {
                return (
                  <img src={res.url} alt={""} style={{width: 100, height: 100}}/>
                )
              } else {
                return (
                  <span style={{fontSize: 12}} className={'mx-4'}>{res.message}</span>
                )
              }
            })}
          </div>
          {/*Target product*/}
          <div
            className={"target-product-container-intermediate ms-3 d-flex flex-column align-items-center justify-content-center position-relative"}>
            <div className={"title-result-header-intermediate d-flex justify-content-center align-items-center"}>
              Target  <InfoButton title={'Target product'} content={'Generate the product shown above by combining reactants and reagents in the proper sequence.'}/>
            </div>
            <div className={"d-flex justify-content-center align-items-center"}>
              <img
                className={"target-product"}
                src={target_product}
                alt={""}/>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
