import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import Lottie from "react-lottie";
import animation from "../../assets/svg/chemistry-animation.json";
import {useDrop} from "react-dnd";
import target from "../../assets/svg/target.json";
import {Item} from "../Item/Item";

type IntermediateStepProps = {
  start_animation: boolean
  current_phase: number
  current_result: any[]
  get_problem_result: (phase: number, reactants: any[], reagent: any[]) =>  Promise<{ phase: number; data: any[]; }>
  set_result: (result: any[]) => void
  mobile: boolean
  clear_data: boolean
};

export const IntermediateStep: React.FC<IntermediateStepProps> = observer((props) => {
  const {start_animation, current_result, get_problem_result, set_result, current_phase, mobile} = props
  const [ResultReactants, setResultReactants] = useState<any>([])
  const [SelectedReagent, setSelectedReagent] = useState<any>([])
  const [Result, setResult] = useState<any>([])
  const [CurrentResult, setCurrentResult] = useState<any>([])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
  };

  const targetOptions = {
    loop: true,
    autoplay: true,
    animationData: target,
  };

  const deleteResultReactants = (id: number) => {
    const reagentsArray = [...ResultReactants]
    const filtered = reagentsArray.filter(function (item) {
      return item.id !== id
    })
    setResultReactants(filtered)
  }

  const deleteSelectedReagent = (id: number) => {
    const reagentsArray = [...SelectedReagent]
    const filtered = reagentsArray.filter(function (item) {
      return item.id !== id
    })
    setSelectedReagent(filtered)
  }



  const [{isOverResultReactants, canDropResultReactants}, dropRefResultReactants] = useDrop({
    accept: 'reactant',
    drop: (item) => setResultReactants((ResultReactants: unknown[]) => {
      return !ResultReactants.includes(item) ? [...ResultReactants, item] : ResultReactants;
    }),
    collect: (monitor) => ({
      isOverResultReactants: monitor.isOver(),
      canDropResultReactants: monitor.canDrop()
    })
  })

  const [{isOverSelectedReagent, canDropSelectedReagent}, dropRefSelectedReagent] = useDrop({
    accept: 'reagent',
    drop: (item) => setSelectedReagent((SelectedReagent: unknown[]) => {
      if (SelectedReagent.length === 0) {
        return [...SelectedReagent, item]
      } else {
        return SelectedReagent
      }
    }),
    collect: (monitor) => ({
      isOverSelectedReagent: monitor.isOver(),
      canDropSelectedReagent: monitor.canDrop()
    })
  })


  const formatDataAndSend = async () => {
    const result = await get_problem_result(current_phase, ResultReactants, SelectedReagent)
    if(result.data !== undefined) {
      setCurrentResult(result.data)
    }
    const results = [...current_result]
    const newResult = results.filter( ite => ite.phase !== result.phase)
    newResult.push(result)
    set_result(newResult)


  }

  useEffect(() => {
    const prevPhase = current_phase - 1
    const result = current_result.find( item => item.phase === prevPhase)
    if (result) {
      setResult(result.data)
    } else {
      setResult([])
    }
  }, [current_result])

  useEffect(() => {
    if (props.clear_data === true) {
      setResultReactants([])
      setSelectedReagent([])
      setCurrentResult([])
    }
  }, [props.clear_data])


  return (
    <>
      {!mobile && (
        <div className={"col-4 bordered-container d-flex"}>
            <div
              className={"card-dotted-border text-center d-flex flex-column justify-content-center align-items-center me-3"}
              ref={dropRefResultReactants}
            >
              {!canDropResultReactants && ResultReactants.map((reactant: { id: any; name: any; image: string; smiles: string }, index: number) => {
                  return (
                    <div key={index}
                         onClick={() => deleteResultReactants(reactant.id)}>
                      <Item extraClass={''} id={reactant.id} image={reactant.image} smiles={reactant.smiles}/>
                    </div>
                  )
                }
              )
              }
              {!isOverResultReactants && !canDropResultReactants && ResultReactants.length === 0 &&
                <span>Select Reactant(s)</span>}
              {isOverResultReactants && <span>Drop Here!</span>}
              {canDropResultReactants && !isOverResultReactants &&
                <Lottie options={targetOptions} height={100} width={100}/>}
            </div>
          <div className={"d-flex flex-column"}>
            <div
              className={"card-red-border text-center d-flex flex-column justify-content-center align-items-center mt-auto"}
              ref={dropRefSelectedReagent}
            >
              {SelectedReagent.map((reagent: { id: any; name: any; image: string; text: string }, index: number) => {
                  return (
                    <div key={index} onClick={() => deleteSelectedReagent(reagent.id)}>
                      <Item extraClass={''} id={reagent.id} image={reagent.image} text={reagent.text}/>
                    </div>
                  )
                }
              )
              }
              {!isOverSelectedReagent && !canDropSelectedReagent && SelectedReagent.length === 0 &&
                <span>Drag and drop a Reagent</span>}
              {isOverSelectedReagent && SelectedReagent.length === 0 && <span>Drop Here!</span>}
              {canDropSelectedReagent && !isOverSelectedReagent && SelectedReagent.length === 0 &&
                <div>
                  <Lottie
                    options={targetOptions}
                    height={100}
                    width={100}
                  />
                </div>}
            </div>
            <svg className={"mx-auto mt-3"} width="75" height="6" viewBox="0 0 75 6" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M75 3L70 0.113249V5.88675L75 3ZM0 3.5H70.5V2.5H0V3.5Z" fill="#6B6A6A"/>
            </svg>
            <div
              className={"start-reaction-button d-flex justify-content-center align-items-center mt-2"}
              onClick={() => formatDataAndSend()}>
              Next reaction
            </div>
          </div>
          <div
              className={" card-blue-border p-3 text-center d-flex flex-column justify-content-center align-items-center ms-3"}>
            {CurrentResult.length > 0 && <>
              {CurrentResult.map((res: any, index: number) => {
                if (res.url) {
                  return (
                      <div key={index}>
                        <Item extraClass={''} id={index} image={res.url} smiles={res.smiles}/>
                      </div>
                  )
                }
              })}
            </>}
            {CurrentResult.length === 0 && <>
              {start_animation && <Lottie
                  options={defaultOptions}
                  height={48}
                  width={48}
              />
              }
              {!start_animation && <>
                <span className={"mt-auto"}>(Intermediate)</span>
                <span className={"mb-auto"}>Products</span>
              </>}
            </> }

          </div>
        </div>
      )}
      {mobile && (
        <div className={"col-12 d-flex"}>
          <div className={"d-flex flex-column"}>
            <div
              className={"card-dotted-border text-center d-flex flex-column justify-content-center align-items-center"}>
              {!start_animation && Result.length === 0 &&
                <div className={'p-5'}>Intermediate Products</div>}
              {start_animation && Result.length === 0 && <div><Lottie
                options={defaultOptions}
                height={48}
                width={48}
              />
              </div>
              }
              {Result.map((res: any, index: number) => {
                if (res.url) {
                  return (
                    <div key={index}>
                      <Item extraClass={''} id={index} image={res.url} smiles={res.smiles}/>
                    </div>
                  )
                } else {
                  return (
                    <span  key={index} style={{fontSize: 12}} className={'mx-4'}>{res.message}</span>
                  )
                }
              })}
            </div>
            <div
              className={"card-dotted-border text-center d-flex flex-column justify-content-center align-items-center mt-3"}
              ref={dropRefResultReactants}
            >
              {ResultReactants.map((reactant: { id: any; name: any; image: string; smiles: string }, index: number) => {
                  return (
                    <div key={index}
                         onClick={() => deleteResultReactants(reactant.id)}>
                      <Item extraClass={''} id={reactant.id} image={reactant.image} smiles={reactant.smiles}/>
                    </div>
                  )
                }
              )
              }
              {!isOverResultReactants && !canDropResultReactants && ResultReactants.length === 0 &&
                <div>Select Reactant(s)</div>}
              {isOverResultReactants && <div>Drop Here!</div>}
              {canDropResultReactants && !isOverResultReactants &&
                <Lottie options={targetOptions} height={50} width={50}/>}
            </div>
          </div>
          <div className={"d-flex flex-column"}>
            <div
              className={"card-red-border-intermediate text-center d-flex flex-column justify-content-center align-items-center mt-auto ms-3"}
              ref={dropRefSelectedReagent}
            >
              {SelectedReagent.map((reagent: { id: any; name: any; image: string; text: string }, index: number) => {
                  return (
                    <div key={index} onClick={() => deleteSelectedReagent(reagent.id)}>
                      <Item extraClass={''} id={reagent.id} image={reagent.image} text={reagent.text} mobile/>
                    </div>
                  )
                }
              )
              }
              {!isOverSelectedReagent && !canDropSelectedReagent && SelectedReagent.length === 0 &&
                <div>Drag and drop a Reagent</div>}
              {isOverSelectedReagent && SelectedReagent.length === 0 && <div className={"p-5"}>Drop Here!</div>}
              {canDropSelectedReagent && !isOverSelectedReagent && SelectedReagent.length === 0 &&
                  <Lottie
                    options={targetOptions}
                    height={50}
                    width={50}
                  />
              }
            </div>
            <svg className={"mx-auto mt-3"} width="75" height="6" viewBox="0 0 75 6" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M75 3L70 0.113249V5.88675L75 3ZM0 3.5H70.5V2.5H0V3.5Z" fill="#6B6A6A"/>
            </svg>
            <div
              className={"start-reaction-button d-flex justify-content-center align-items-center mt-auto ms-2"}
              onClick={() => formatDataAndSend()}>
              Start reaction
            </div>
          </div>
          <div
            className={"card-blue-border p-3 text-center d-flex flex-column justify-content-center align-items-center ms-3"}>
            {CurrentResult.length > 0 && <>
              {CurrentResult.map((res: any, index: number) => {
                if (res.url) {
                  return (
                    <div key={index}>
                      <Item extraClass={''} id={index} image={res.url} smiles={res.smiles}/>
                    </div>
                  )
                }
              })}
            </>}
            {CurrentResult.length === 0 && <>
              {start_animation && <Lottie
                options={defaultOptions}
                height={48}
                width={48}
              />
              }
              {!start_animation && <>
                <span className={"mt-auto"}>(Intermediate)</span>
                <span className={"mb-auto"}>Products</span>
              </>}
            </> }

          </div>
        </div>
      )}
    </>
  )
})
