import React, {useEffect, useRef, useState} from "react";

// react-router-dom
import {useNavigate} from "react-router-dom";


// Images
import {observer} from "mobx-react-lite";
import {PageWeb} from "../../components";
import {toast} from "react-toastify";
import {useStores} from "../../models/root-store/root-store-context";
import {ROUTES} from "../../constants";

// Authentication layout components

function ActivateUser() {
  const rootStore = useStores()
  const navigate = useNavigate()
  const getUrls = window.location.href.split('activate-user/')[1].split('/')
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const activateAccount = () => {
    const data = {
      uidb64: getUrls[0],
      token: getUrls[1]
    }
    rootStore.environment.api.activateAccount(data).then((result) => {
      console.log(result)
      if (result.kind === "ok") {
        setSuccess(true)
        setTimeout(() => {
          window.location.replace(ROUTES.SPLASH)
        }, 3000)
      } else if(result.kind === "bad-data") {
        setError(true)
        if (result.errors.token) {
          toast.warn(result.errors.token, {
            position: toast.POSITION.TOP_CENTER
          });
          navigate(ROUTES.SPLASH)
        } else {
          toast.warn(result.errors?.errors, {
            position: toast.POSITION.TOP_CENTER
          });
          navigate(ROUTES.SPLASH)
        }
      } else {
        setError(true)
        navigate(ROUTES.SPLASH)
      }
    })
      .catch(err => {
        setError(true)
        navigate(ROUTES.SPLASH)
      })
  }

  useEffect(() => {
    activateAccount()
  }, [])


  return (
    <PageWeb header={false}>
      <div className="row mhvh100 justify-content-between align-items-center p-0 m-0">
        <div className={"col-12 p-0 m-0 d-flex justify-content-between align-items-center"}>
          {success && <span style={{color: "white", fontSize: 35, textAlign: "center"}} className={"mx-auto"}>Account Activated</span>}
        </div>
      </div>
    </PageWeb>
  )
}

export default observer(ActivateUser);
