import {ApiConfig, DEFAULT_API_CONFIG} from "./api-config"
import * as Types from "./api.types"
import {ApiBase} from "./api-base";
import {SimpleGetResult} from "./api.types";
import {ApiResponse} from "apisauce";
import {getGeneralApiProblem} from "./api-problem";


/**
 * Manages all requests to the API.
 */
export class Api extends ApiBase {

    constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
        super(config)
    }

    async getSynthesisProblems(): Promise<Types.SimpleGetResult> {
        return this.simple_get(`/ReactionSynthesisList.py`)
    }

    async getTextbooks(): Promise<Types.SimpleGetResult> {
        return this.simple_get(`/TextbookList.py`)
    }

    async getReactionSubjectCategories(): Promise<Types.SimpleGetResult> {
        return this.simple_get(`/ReactionCategoryList.py`)
    }

    async getProblem(reaction_synthesis_id: number): Promise<Types.SimpleGetResult> {
        return this.simple_get(`/GenerateProblem.py?reaction_synthesis_id=${reaction_synthesis_id}&userID=null&user_class_id=&ProductWeb=Yes`)
    }

    async getProblemResult(reactant: string, reagent: number, productSmiles: string): Promise<Types.SimpleGetResult> {
        return this.simple_get(`/GenerateProduct.py?PathwayWeb=Yes&reactant=${reactant}&product=*&reagent_id=${reagent}&productSmiles=${productSmiles}`)
    }

    async getProblemSolution(formData: any): Promise<Types.SimplePostResult> {
        return this.simple_post(`/PathwayWeb.py`, formData)
    }

    activateAccount(data: any) {
        return this.activateAccountBackend(`/api/v1/user/activate/`, data)
    }
    async activateAccountBackend<T extends SimpleGetResult>(path: string, extra_params?: {}, axios?: {}): Promise<T> {

        if (!this.apisauce) {
            return {kind: "unknown", temporary: true} as T
        }

        this.apisauce?.setBaseURL('https://reaction-explorer-31933.botics.co/')

        const response: ApiResponse<any> = await this.apisauce.post(path, extra_params, axios)

        if (!response.ok) {
            if (response.status === 400) {
                return {kind: "bad-data", errors: response.data} as T
            } else {
                const problem = getGeneralApiProblem(response)
                if (problem) return problem as T
            }
        }

        this.apisauce?.setBaseURL(this.config.url)

        try {
            return {kind: "ok", data: response.data} as T
        } catch {
            return {kind: "bad-data"} as T
        }
    }

}
