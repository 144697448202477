import React, {useState} from 'react'
import {useDrag} from 'react-dnd'
import {BsPrefixProps, Omit} from "react-bootstrap/helpers";
import {Button, Modal, ModalProps} from "react-bootstrap";
import Lottie from "react-lottie";
import {dimensionsFixArrowService} from "../../helpers";


interface ItemInterface  {
  id: number
  extraClass: string
  image: string
  smiles?: string
  text?: string
  item_type?: string
  mobile?: boolean
}

const styles = {
  notMobile: {
    unselected: {
      width: window.innerWidth <= 1000 ? 82 : 102,
      height: window.innerWidth <= 1000 ? 82 : 102
    },
    unselectedText: {
      width: window.innerWidth <= 1000 ? 82 : 102,
      height: window.innerWidth <= 1000 ? 82 : 102,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'black',
    },
    selected: {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'red',
      width: window.innerWidth <= 1000 ? 82 : 102,
      height: window.innerWidth <= 1000 ? 82 : 102
    },
    text: {
      fontSize:  window.innerWidth <= 1000 ? 8 : 10,
    },
    image: {
      width: window.innerWidth <= 1000 ? 82 : 102,
      height: window.innerWidth <= 1000 ? 82 : 102
    }
  },
  mobile: {
    unselected: {
      width: 68,
      height: 68
    },
    unselectedText: {
      width: 68,
      height: 68,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'black',
    },
    selected: {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'red',
      width: 68,
      height: 68
    },
    text: {
      fontSize: 7,
    },
    image: {
      width: 68,
      height:68
    }
  }

}


export const Item: React.FC<ItemInterface> = (
  {
    id,
    extraClass,
    image,
    smiles,
    text,
    item_type = 'reactant',
    mobile = false

  }) => {
  const [ModalElement, setModalElement] = useState(false)
  const [{isDragging}, dragRef] = useDrag({
    type: item_type,
    item: {id, extraClass, image, smiles, text},
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  const [SelectedImage, setSelectedImage] = useState<string>('')


  const itemStyle = mobile === false ? styles.notMobile : styles.mobile

  return (
    <div
      style={isDragging ? itemStyle.selected : image !== '' ? itemStyle.unselected : itemStyle.unselectedText}
      className={"item-chem d-flex justify-content-center align-items-center " + extraClass + " "}
      ref={dragRef}
      onClick={() => {
        if (image !== "") {
          setSelectedImage(dimensionsFixArrowService(image, 300, 300))
          setModalElement(true)
        }
      }}
    >
      {image !== "" && <img src={image} alt={''} style={itemStyle.image} />}
      {image === "" && <span style={itemStyle.text}>{text}</span>}
      <Modal
          show={ModalElement}
          onHide={() => {
            setTimeout(() => setModalElement(false), 100)
          }}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <h4>Element</h4>
          <div className={'d-flex align-items-center justify-content-center'}>
            <img src={SelectedImage} alt={''} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => {
            setTimeout(() => setModalElement(false), 100)
          }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )


}
