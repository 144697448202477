

export const ROUTES = {
  SPLASH:"/",
  START_FORM:"/start-form",
  ACTIVATE_USER: "/activate-user/*",
  SYNTHESIS_EXPLORER: (params: any) => `/synthesis-explorer/${params}` ,
}

export const BASE_URL = 'https://re.edugen.wiley.com/cgibin/tutorial/'
