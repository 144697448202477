import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import './SynthesisExplorer.css';
import {FirstStep, IntermediateStep, Item, LastStep, PageWeb} from "../../components"
import {useParams} from "react-router-dom"
import reactant from '../../assets/images/reactants.png'
import reagent from '../../assets/images/reagents.png'
import {dimensionsFixArrowService, formatData, genericApiError} from "../../helpers"
import {useStores} from "../../models/root-store/root-store-context"
import {toast} from "react-toastify";
import {Button, Modal, ModalProps} from "react-bootstrap";
import {Omit, BsPrefixProps} from 'react-bootstrap/esm/helpers';
import {BASE_URL} from "../../constants";
import Lottie from "react-lottie";
import congrats from "../../assets/svg/congrats-animation.json";
import InfoButton from "../../components/InfoButton/InfoButton";



export const SynthesisExplorer: React.FC = observer(() => {
  const rootStore = useStores()
  let {id} = useParams();
  const [Loading, setLoading] = useState<boolean>(false);
  const [StartAnimation, setStartAnimation] = useState<boolean>(false)
  const [Result, setResult] = useState<any>([])

  const [ResultImage, setResultImage] = useState<string>("")
  const [ResultImageMin, setResultImageMin] = useState<string>("")
  const [ReactantsBase, setReactantsBase] = useState<any>([])
  const [ReagentsBase, setReagentsBase] = useState<any>([])
  const [ReactantsBaseMin, setReactantsBaseMin] = useState<any>([])
  const [ReagentsBaseMin, setReagentsBaseMin] = useState<any>([])
  const [width, setWidth] = useState<number>(window.innerWidth)
  const [Title, setTitle] = useState<string>('')
  const [SynthesisProblemOptions, setSynthesisProblemOptions] = useState<any[]>([])
  const [modalShow, setModalShow] = useState(false);
  const [HelpTextProblem, setHelpTextProblem] = useState([]);
  const [ClearDataControler, setClearDataControler] = useState(false);
  const [ResultCompare, setResultCompare] = useState('');
  const [SolutionPostParams, setSolutionPostParams] = useState([]);

  const [Phases, setPhases] = useState<number>(2)
  const [CurrentStep, setCurrentStep] = useState<number>(1)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  const getRandomProblem = () => {
    const randomProblem = SynthesisProblemOptions[Math.floor(Math.random() * SynthesisProblemOptions.length)];
    getProblemData(randomProblem.value)
    setTitle(randomProblem.label)
    setPhases(randomProblem.maxSteps)
  }

  const getSynthesisProblems = () => {
    rootStore.environment.api.getSynthesisProblems().then((result: any) => {
      setLoading(false)
      if (result.kind === "ok") {
        const currentProblem = result.data.results.find((res: any) => {
          return res.id === parseInt(id as string)
        })
        setTitle(currentProblem.name)
        setPhases(currentProblem.maxSteps)
        setSynthesisProblemOptions(formatData(result.data.results))
      }
    })
  }

  const getProblemData = (id: string) => {
    setLoading(true)
    rootStore.environment.api.getProblem(parseInt(id)).then((result: any) => {
      // delay para mostrar animacion
      setTimeout(() => setLoading(false), 800)
      if (result.kind === "ok") {
        const {product, reactants, reagents, reagents_link_ref, solution_post_params} = result.data.results
        setHelpTextProblem(reagents_link_ref)
        setResultCompare(product.smiles)
        setSolutionPostParams(solution_post_params)
        setResultImage(dimensionsFixArrowService(product.url, 150, 200))
        setResultImageMin(dimensionsFixArrowService(product.url, 60, 80))
        // Reactants
        const formatedReactants: { id: any; smiles: any; url: string; }[] = []
        const formatedReactantsMin: { id: any; smiles: any; url: string; }[] = []
        reactants.map((reactant: any) => {
          const formatedReactant = {
            id: reactant.id,
            smiles: reactant.smiles,
            url: dimensionsFixArrowService(reactant.url, 100, 100),
          }
          formatedReactants.push(formatedReactant)
          const formatedReactantMin = {
            id: reactant.id,
            smiles: reactant.smiles,
            url: dimensionsFixArrowService(reactant.url, 70, 70),
          }
          formatedReactantsMin.push(formatedReactantMin)
        })
        setReactantsBase(formatedReactants)
        setReactantsBaseMin(formatedReactantsMin)
        // Reagents
        const formatedReagents: { id: number; text: string; url: string; }[] = []
        const formatedReagentsMin: { id: number; text: string; url: string; }[] = []
        reagents.map((reagent: any) => {

          const formatedReagent = {
            id: reagent.id,
            text: reagent.text,
            url: dimensionsFixArrowService(reagent.url, 100, 100),
          }
          formatedReagents.push(formatedReagent)

          const formatedReagentMin = {
            id: reagent.id,
            text: reagent.text,
            url: dimensionsFixArrowService(reagent.url, 70, 70),
          }
          formatedReagentsMin.push(formatedReagentMin)
        })
        setReagentsBase(formatedReagents)
        setReagentsBaseMin(formatedReagentsMin)
      }
    }).catch((error: any) => {
      setLoading(false)
      genericApiError()
    })
  }

  const getProblemResultTest = async (phase: number, reactants: any[], reagent: any[]) => {
    const formatedReactants: any[] = []

    const data = {
      phase,
      data: formatedReactants
    }

    if (reactants.length === 0) {
      toast.warn("Please select at least one reactant", {
        position: toast.POSITION.TOP_CENTER
      });
      return data
    }

    if (reagent.length === 0) {
      toast.warn("Please select at least one reagent", {
        position: toast.POSITION.TOP_CENTER
      });
      return data
    }

    let smilesResult = ''
    reactants.map((reactant: any) => {
      if (smilesResult !== '') {
        smilesResult += ',' + reactant.smiles
      } else {
        smilesResult += reactant.smiles
      }
    })

    const smiles = encodeURIComponent(smilesResult)
    const reagentId = reagent[0].id

    setStartAnimation(true)
    return rootStore.environment.api.getProblemResult(smiles, reagentId, '').then((result: any) => {
      if (result.data.length === 0) {
        toast.info("The combination of these reactions does not produce any product.", {
          position: toast.POSITION.TOP_CENTER
        });
      }
      if (phase === Phases) {
        rootStore.environment.api.getProblemResult(smiles, reagentId, ResultCompare).then((res: any) => {
          res.data.map((reacta: any) => {
            if (reacta?.solution_message?.solved === true) {
              setModalShow(true)
            }
          })
        })
      }
      setStartAnimation(false)
      result.data.map((reactant: any) => {
        if (reactant.url) {
          const formatedReactant = {
            id: reactant.id,
            smiles: reactant.smiles,
            url: dimensionsFixArrowService(reactant.url, 100, 100),
          }
          formatedReactants.push(formatedReactant)
        } else {
          formatedReactants.push(reactant)
        }
      })
      return data

    }).catch((error: any) => {
      setStartAnimation(false)
      genericApiError()
      return data
    })
  }

  const clearData = () => {

    setClearDataControler(true)
    setResult([])
    setCurrentStep(1)
    setTimeout(() => setClearDataControler(false), 500)
    toast.success("Data cleared", {
      position: toast.POSITION.TOP_CENTER
    });
  }

  const createSolutionPath = () => {
    const formData = new FormData()
    SolutionPostParams.forEach((data, index) => {
      formData.append(data[0], data[1])
    })
    rootStore.environment.api.getProblemSolution(formData).then((res: any) => {
      const win = window.open("", "_blank");
      // @ts-ignore
      win.document.body.innerHTML = res.response;
    })
  }

  useEffect(() => {
    if (id) {
      getProblemData(id)
      getSynthesisProblems()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 767;

  const congratsAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: congrats,
  };

  const SuccessModal = (props: JSX.IntrinsicAttributes & Omit<Pick<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "key" | keyof React.HTMLAttributes<HTMLDivElement>> & { ref?: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined; }, BsPrefixProps<"div"> & ModalProps> & BsPrefixProps<"div"> & ModalProps & { children?: React.ReactNode; }) =>  {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
          <Modal.Body>
            <h4>Congratulations</h4>

            <Lottie options={congratsAnimationOptions} height={300} width={300}/>
            <p>
              You have solved this synthesis!
            </p>
          </Modal.Body>
        </Modal>
    );
  }

  return (
    <PageWeb header header_title={Title} loading={Loading} left_button_header_action={null}>
      {/*Desktop version*/}
      {!isMobile && <div className="row mt-5 mt-md-0 mt-lg-1">
        <div className={"col-12"}>
          <div className={"card reaction-container"}>
            <div className={"card-body overflow-auto"}>
              <div className={"row flex-row flex-nowrap"}>
                {/*First Step*/}
                <FirstStep
                  get_problem_result={getProblemResultTest}
                  current_result={Result}
                  set_result={setResult}
                  mobile={false}
                  clear_data={ClearDataControler}
                />

                {/*Intermediate Step*/}
                {[...Array(Phases - 1)].map((el, index) => {
                  const step = index + 2
                  return (
                    <IntermediateStep
                      key={index}
                      start_animation={StartAnimation}
                      current_phase={step}
                      current_result={Result}
                      get_problem_result={getProblemResultTest}
                      set_result={setResult}
                      mobile={false}
                      clear_data={ClearDataControler}
                    />
                  )
                })}

                {/*Last Step*/}
                <LastStep
                  target_product={ResultImage}
                  start_animation={StartAnimation}
                  current_phase={Phases}
                  current_result={Result}
                  mobile={false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={"col-12 mt-3"}>
          <div className={"row"}>
            <div className={"col-6 position-relative"}>
              <div className={"reactants-container"}>
                <div className={"reactants-title-container d-flex justify-content-center align-items-center"}>
                  <span className={'ms-4'}>Reactants available</span>
                  <InfoButton title={'Reactants'} content={'Drag and drop a reactant to add it to the synthesis pathway.'} direction={'top'}/>
                </div>
                <div className={"reaction-title-circle d-flex justify-content-center align-items-center"}>
                  <img src={reactant} style={{width: 60, height: 60}} alt={""}/>
                </div>
                <div className={"pt-5 ps-3 row justify-content-start align-items-start"}>
                  {ReactantsBase.map((reactant: { id: any; url: any; smiles: string }, index: number) => {
                    return (
                      <Item
                        key={index}
                        extraClass={''}
                        id={index}
                        image={reactant.url}
                        smiles={reactant.smiles}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
            <div className={"col-6 position-relative"}>
              <div className={"reactants-container"}>
                <div
                  className={"reactants-title-container reagent-background-color d-flex justify-content-center align-items-center"}>
                  <span className={'ms-4'}>Reagents available</span>
                  <InfoButton title={'Reagents'} content={'Drag and drop a reagent to add it to the synthesis pathway.'} direction={'top'}/>
                </div>
                <div className={"reaction-title-circle d-flex justify-content-center align-items-center"}>
                  <img src={reagent} style={{width: 50, height: 45}} alt={""}/>
                </div>
                <div className={"pt-5 ps-3 row justify-content-start align-items-start"}>
                  {ReagentsBase.map((reagent: { id: any; url: any; text: any; }, index: number) => {
                    return (
                    <Item
                      key={index}
                      extraClass={''}
                      id={reagent.id}
                      image={reagent.url}
                      text={reagent.text}
                      item_type={'reagent'}
                    />
                      )
                  })}
                </div>
              </div>
            </div>
            <div className={"col-4"}>

            </div>
          </div>
          <div className={'row mt-5'}>
            <div  className={'col-4 d-flex justify-content-center align-items-center'}>
              <button className={"d-flex justify-content-center align-items-center button-next-puzzle"}
                      onClick={() => getRandomProblem()}
              >
                Next puzzle
              </button>
            </div>
            <div  className={'col-4 d-flex justify-content-center align-items-center'}>
              <button className={"d-flex justify-content-center align-items-center button-next-puzzle"}
                      onClick={() => createSolutionPath()}
              >
                Solution
              </button>
            </div>
            <div  className={'col-4 d-flex justify-content-center align-items-center'}>
              <button className={"d-flex justify-content-center align-items-center button-next-puzzle"}
                      onClick={() => clearData()}
              >
                Clear data
              </button>
            </div>
          </div>
        </div>
        <span className={"text-white fw-bold mt-5"}>RESOURCES</span>
        {HelpTextProblem.map((text: any) => (<a href={BASE_URL + text.url} className={"text-white fw-bold"} target={'_blank'}>{text.tag}</a>))}
        <SuccessModal
            show={modalShow}
            onHide={() => setModalShow(false)}
        />
      </div>}
      {/*Mobile version*/}
      {isMobile && (
        <div className="row mt-2 mb-3">
        {/*Selector*/}
        <div className={"col-12"}>
          <div className={"card reaction-container-min"}>
            <div className={"card-body overflow-auto"}>
              <div className={"row flex-row flex-nowrap m-0"}>
                <div className={`col-4 p-1 d-flex flex-column ${CurrentStep === 1 ? 'reactants-container-min-selected': ''}`} onClick={() => setCurrentStep(1)}>
                  <div className={'d-flex mt-1'}>
                    <div className={"card-blue-border  text-center me-3"}>
                    </div>
                    <div className={"d-flex flex-column"}>
                      <div className={"card-red-border text-center mb-2"}></div>
                      <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30 3L25 0.113249V5.88675L30 3ZM0 3.5H25.5V2.5H0V3.5Z" fill="#6B6A6A"/>
                      </svg>
                    </div>
                  </div>
                  <div className={'text-center mt-auto'}>
                    Step 1
                  </div>
                </div>

                {[...Array(Phases - 1)].map((el, index) => {
                  const step = index + 2
                  return (
                    <div className={`col-4 p-1 bordered-container d-flex flex-column ${CurrentStep === step ? 'reactants-container-min-selected': ''}`} key={index}  onClick={() => setCurrentStep(step)}>
                      <div className={'d-flex mt-1'}>
                        <div className={"d-flex flex-column me-3"}>
                          <div className={"card-dotted-border-min text-center"}>
                          </div>
                          <div className={"card-dotted-border-min  text-center mt-3"}>
                          </div>
                        </div>
                        <div className={"d-flex flex-column"}>
                          <div className={"card-red-border text-center mb-2"}></div>
                          <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30 3L25 0.113249V5.88675L30 3ZM0 3.5H25.5V2.5H0V3.5Z" fill="#6B6A6A"/>
                          </svg>
                        </div>
                      </div>
                      <div className={'text-center mt-auto'}>
                        Step {step}
                      </div>
                    </div>
                  )
                })}


                <div className={`col-4 d-flex ${CurrentStep === (Phases + 1) ? 'reactants-container-min-selected': ''}`} onClick={() => setCurrentStep(Phases + 1)}>
                  <div className={"card-dotted-border-min p-2 text-center col-6"}>
                  </div>
                  <div className={"d-flex flex-column "}>
                    <div className={"title-result-header-min d-flex justify-content-center align-items-center p-1 text-center"}>
                      Target product
                    </div>
                    <div className={"target-product-container-min d-flex align-items-center"}>
                        <img
                          src={ResultImageMin}
                          alt={""} style={{width: "auto", height: 80}}/>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        {/*Current element selected*/}
        <div className={"col-12 mt-4"}>
          <div className={"card current-element"}>
            <div className={"card-body"}>
              {CurrentStep === 1 &&
                <FirstStep
                  get_problem_result={getProblemResultTest}
                  current_result={Result}
                  set_result={setResult}
                  mobile
                  start_animation={StartAnimation}
                  clear_data={ClearDataControler}
                />
              }
              {/*Intermediate Step*/}
              {[...Array(Phases - 1)].map((el, index) => {
                const step = index + 2
                if (CurrentStep === step)
                  return (
                    <IntermediateStep
                      key={index}
                      start_animation={StartAnimation}
                      current_phase={step}
                      current_result={Result}
                      get_problem_result={getProblemResultTest}
                      set_result={setResult}
                      mobile
                      clear_data={ClearDataControler}
                    />
                  )
              })}

              {/*Last Step*/}
              {CurrentStep === (Phases + 1) &&
                <LastStep
                  target_product={ResultImage}
                  start_animation={StartAnimation}
                  current_phase={Phases}
                  current_result={Result}
                  mobile
                />
              }
            </div>
          </div>
        </div>
        {/*Reagent and Reactants*/}
        <div className={"col-12 mt-5"}>
          <div className={"row"}>
            <div className={"col-6"}>
              <div className={"reagent-reactant-container position-relative"}>
                <div className={"reactants-title-container d-flex justify-content-center align-items-center"}>
                  Reactants available
                  <InfoButton title={'Reactants'} content={'Drag and drop a reactants to add it to the synthesis pathway.'} direction={'top'}/>
                </div>
                <div className={"reaction-title-circle d-flex justify-content-center align-items-center"}>
                  <img src={reactant} style={{width: 40, height: 40}} alt={""}/>
                </div>
                <div className={"pt-5 row justify-content-center scrollable-list"}>
                  {ReactantsBaseMin.map((reactant: { id: any; url: any; smiles: string }, index: number) => {
                    return (
                      <Item
                        key={index}
                        extraClass={'p-1'}
                        id={reactant.id}
                        image={reactant.url}
                        smiles={reactant.smiles}
                        item_type={'reactant'}
                        mobile={true}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
            <div className={"col-6"}>
              <div className={"reagent-reactant-container position-relative"}>
                <div
                  className={"reactants-title-container reagent-background-color d-flex justify-content-center align-items-center"}>
                  Reagents available
                  <InfoButton title={'Reagents'} content={'Drag and drop a reagent to add it to the synthesis pathway.'} direction={'top'}/>
                </div>
                <div className={"reaction-title-circle d-flex justify-content-center align-items-center"}>
                  <img src={reagent} style={{width: 40, height: 35}} alt={""}/>
                </div>
                <div className={"pt-5 row justify-content-center scrollable-list"}>
                  {ReagentsBaseMin.map((reagent: { id: any; url: any; text: any; }, index: number) => {
                    return (
                      <Item
                        key={index}
                        extraClass={'p-1'}
                        id={reagent.id}
                        image={reagent.url}
                        text={reagent.text}
                        item_type={'reagent'}
                        mobile={true}
                      />
                      )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
          <div className={'col-12 mt-4'}>
            <div className={'row mb-2'}>
              <div  className={'col-4 d-flex justify-content-center align-items-center'}>
                <button className={"d-flex justify-content-center align-items-center button-next-puzzle"}
                        onClick={() => getRandomProblem()}
                >
                  Next puzzle
                </button>
              </div>
              <div  className={'col-4 d-flex justify-content-center align-items-center'}>
                <button className={"d-flex justify-content-center align-items-center button-next-puzzle"}
                        onClick={() => createSolutionPath()}
                >
                  Solution
                </button>
              </div>
              <div  className={'col-4 d-flex justify-content-center align-items-center'}>
                <button className={"d-flex justify-content-center align-items-center button-next-puzzle"}
                        onClick={() => clearData()}
                >
                  Clear data
                </button>
              </div>
            </div>
          </div>
          <SuccessModal
              show={modalShow}
              onHide={() => setModalShow(false)}
          />
        {/*Reagent and Reactants*/}
        <span className={"text-white fw-bold mt-5"}>RESOURCES</span>
        {HelpTextProblem.map((text: any) => (<a href={BASE_URL + text.url} className={"text-white fw-bold"} target={'_blank'}>{text.tag}</a>))}
      </div>
      )}
    </PageWeb>
  );
});

export default SynthesisExplorer;
