import React from 'react'
import {OverlayTrigger, Popover} from "react-bootstrap"
import {Placement} from "react-bootstrap/types";

type InfoButtonProps = {
  title: string
  content: string
  direction?: string
};

export const InfoButton: React.FC<InfoButtonProps> = React.forwardRef((props, ref) => {
  const {title, content} = props
  const place:any = props.direction || 'left';
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">{title}</Popover.Header>
      <Popover.Body>
        {content}
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement={place} overlay={popover}>
      <button className={'info-hint'}>?</button>
    </OverlayTrigger>
  )
});

export default InfoButton;
